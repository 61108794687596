import { memo } from "react"
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, Text } from "@chakra-ui/react"

const CustomerOrdersOutOfStockModal = ({ outOfStockItems = [], onClose, isOpen }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="unset" border="2px" bg="brand.offWhite">
          <ModalHeader bg="brand.lightSand">These products are currently out of stock</ModalHeader>
          <ModalBody>
            {outOfStockItems?.map(({ title, totalQty, oosQty }) => (
              <Text key={title} mb={3}>
                {oosQty} of {totalQty} {" — "} {title}
              </Text>
            ))}
          </ModalBody>

          <ModalFooter justifyContent="center">
            <Button onClick={onClose}>Ok</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}

const MemoCustomerOrdersOutOfStockModal = memo(CustomerOrdersOutOfStockModal)
export { MemoCustomerOrdersOutOfStockModal as CustomerOrdersOutOfStockModal }
