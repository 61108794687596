import { memo } from "react"
import { Stack, Spinner, Text, Box } from "@chakra-ui/react"
import { CustomerOrdersItem } from "./CustomerOrdersItem"

type CustomerOrdersListProps = {
  orders: Array<any>
  loading: boolean
  error: string
}

const CustomerOrdersList: React.FC<CustomerOrdersListProps> = ({ orders, loading }) => {
  const hasOrders = !!orders?.length

  if (hasOrders) {
    return (
      <Stack direction="column" width="full" spacing={0}>
        {orders?.map((order: any) => (
          <CustomerOrdersItem key={order?.id?.toString()} order={order} />
        ))}
      </Stack>
    )
  }

  if (loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  return <Text fontWeight={700}>No orders</Text>
}

const MemoCustomerOrdersList = memo(CustomerOrdersList)
export { MemoCustomerOrdersList as CustomerOrdersList }
