import { memo } from "react"
import { Box, Spinner } from "@chakra-ui/react"
import { useCustomerOrders, useCustomerOrder } from "@app/hooks/useCustomer"
import { CustomerOrdersList } from "./CustomerOrdersList"
import { CustomerOrdersItem } from "./CustomerOrdersItem"

import type { Location } from "@root/types/global"

type CustomerOrdersCardProps = {
  location: Location
}

const CustomerOrdersCard: React.FC<CustomerOrdersCardProps> = ({ location }) => {
  const { orders, loading, error } = useCustomerOrders(200)
  const orderId = location?.pathname?.split("/account/orders/")[1] || ""
  const { order, loading: orderLoading, error: orderError } = useCustomerOrder(orderId, location?.search || "")

  if (loading) {
    return (
      <Box>
        <Spinner />
      </Box>
    )
  }

  if (orderId) {
    return <CustomerOrdersItem key={orderId} order={order} showItems />
  }

  return <CustomerOrdersList orders={orders} loading={loading || orderLoading} error={error || orderError} />
}

const MemoCustomerOrdersCard = memo(CustomerOrdersCard)
export { MemoCustomerOrdersCard as CustomerOrdersCard }
