import { useCustomerContext } from "@app/providers/customer"
import { CustomerOrdersCard } from "./CustomerOrdersCard"
import { CustomerPageLayout } from "../CustomerPageLayout"
import { memo } from "react"

import type { Props } from "@app/pages/account/orders"
import type { PageProps } from "@root/types/global"

const CustomerOrders: React.FC<PageProps<Props>> = ({ location, page }) => {
  const { customer } = useCustomerContext()

  return (
    <CustomerPageLayout content={page?.content} location={location} subtitle={page?.subtitle} title={page?.title}>
      {customer?.id && <CustomerOrdersCard location={location} />}
    </CustomerPageLayout>
  )
}

export default memo(CustomerOrders)
